.Reasons {
  padding: 0 2rem;
  display: flex;
  gap: 2rem;
  position: relative;
}

.left-r {
  flex: 50%;
}
.right-r {
  flex: 50%;
  text-transform: uppercase;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.title1 {
  font-weight: bold;
  color: var(--orange);
}
.right-r div span {
  color: white;
  font-size: 3rem;
  font-weight: bold;
}
/* image grid */
.row {
  display: flex;
  flex-wrap: wrap;
}

/* Create two equal columns that sits next to each other */
.column {
  flex: 50%;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
}
.img_11 {
  width: 12rem;
  height: 28rem;
  padding-right: 0.7rem;
}
.img_12 {
  width: 24rem;
  padding-bottom: 0.5rem;
}
.img_21 {
  width: 12rem;
  height: 12rem;
  padding-right: 0.7rem;
}
.img_22 {
  width: 11.4rem;
  height: 12rem;
}

.details-r {
  font-size: 1rem;
  gap: 1rem;
}
.details-r div span {
  font-size: 1rem;
}
.details-r div {
  display: flex;
  gap: 1rem;
  line-height: 3rem;
}
.details-r div img {
  width: 2rem;
  height: 2rem;
  padding-top: 1rem;
}
.partners {
  display: flex;
  gap: 1rem;
}
.partners img {
  width: 2.5rem;
}

@media screen and (max-width: 768px) {
  .Reasons {
    flex-direction: column;
  }
  .img_11 {
    width: 14rem;
    height: 26rem;
    padding-right: 0.7rem;
    padding-left: 0.5rem;
    padding-bottom: 1rem;
  }
  .img_12 {
    display: none;
  }
  .img_21 {
    width: 7.5rem;
    height: 10rem;
    padding-right: 0.4rem;
  }
  .img_22 {
    width: 7.5rem;
    height: 10rem;
  }

  .right-r {
    font-size: small;
  }
  .right-r > div > span {
    font-size: xx-large;
  }

  /* details */
  .details-r {
    position: relative;
    left: 0rem;
    font-size: 0.5rem;
    gap: 0.5rem;
  }
  .details-r div span {
    font-size: 0.8rem;
    line-height: 2rem;
  }
  .details-r div {
    display: flex;
    gap: 0.7rem;
    line-height: 6rem;
  }
  .details-r div img {
    width: 2rem;
    height: 2rem;
    padding-top: 0rem;
  }
}
