.plans-container {
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  line-height: 2rem;
}

.plan {
  display: flex;
  flex-direction: column;
  background: var(--caloryCard);
  color: white;
  padding: 1.5rem;
  width: 15rem;
}

.plan:nth-child(2) {
  background: var(--planCard);
  transform: scale(1.1);
}
.plan svg {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}
.plan .name {
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem;
}

.plan .price {
  font-size: 3rem;
  font-weight: bold;
  padding: 1rem;
  padding-bottom: 2rem;
}
.senten {
  font-size: 0.8rem;
  padding-top: 1rem;
}

.features {
  display: flex;
  flex-direction: column;
}
.tick {
  width: 1rem;
  height: 1rem;
}

.plans:nth-child(2) svg {
  fill: white;
}

.plans:nth-child(2) button {
  color: orange;
}

.plans-b-1 {
  width: 32rem;
  height: 23rem;
  top: 6rem;
  left: 0;
}
.plans-b-2 {
  width: 32rem;
  height: 23rem;
  top: 10rem;
  right: 0;
}

@media screen and (max-width: 768px) {
  .plans {
    flex-direction: column;
  }
  .plan:nth-child(2) {
    background: var(--planCard);
    transform: scale(1);
  }
}
